@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
body {
  margin: 0;
  font-family: 'Cairo', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#popupEl {
    transition: .5s opacity ease;
    display: none;
}
.overlay {
    background: #e7e7e778;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
}
.popup {
    position: fixed;
    left: 0;
    right: 0;
    width: 368px;
    background: #3c3c3c;
    color: #fff;
    direction: rtl;
    padding: 15px;
    margin: 0 auto;
    top: calc(50vh - 100px);
    border-radius: 5px;
    box-shadow: 1px 2px 7px 2px #000;
}
.popup__header {
    display: flex;
    gap: 236px;
    height: 46px;
}
.popup__header h4 {
    font-size: 116%;
}
button.close-btn {
    align-self: baseline;
    background: #e02023;
    color: #fff;
    border-radius: 5px;
    border:none;
    border-bottom: 5px solid #6a080a;
    font-size: 17px;
    cursor: pointer;
    transition: border .8s ease;
}
button.close-btn:hover {
    border-bottom: 1px solid #6a080a;

    background: #911214;
}

.popup__content img {
    width: 57px;
    margin-left: 7px;
    margin-bottom: -5px;
}
.social_links {
    list-style: none;
    padding: 10px 15px;
}
.social_links li
{
    display: inline-block;
    float: right;
}
.social_links li a {
    color: #fff;
    background: #1752b6;
    padding: 3px 10px;
    border-radius: 4px;
    margin: 0 3px;
    text-decoration: none;
    font-size: 14px;
    border-bottom: 5px solid #0F1F1F;
    transition: border .5s ease;
}
.social_links li a:hover{
    background: #254e95;
    border-bottom: 2px solid #254e95;
}
.social_links li a i {
    font-size: 15px;
}
